<template>
<div class="algorithms-wrapper">
  <b-loading :is-full-page="false" :active="loading" />
  <template v-if="!loading">
    <b-field grouped group-multiline>
      <em v-if="error">{{$t('error-fetch-algorithms')}}</em>
      <div class="control" v-else-if="algorithms.length > 0" v-for="(algo) in algorithms" :key="algo.id">
        <b-taglist attached>
          <b-tag :class="algo.tag">
            {{algo.name}}
          </b-tag>
        </b-taglist>
      </div>
      <em v-else-if="algorithms.length === 0">{{$t('no-algorithms')}}</em>
    </b-field>
  </template>
</div>
</template>

<script>
import {Property, PropertyCollection} from 'cytomine-client';
import constants from '@/utils/constants.js';

export default {
  name: 'cellsai-algorithms',
  props: {
    object: {type: Object},
  },
  data() {
    return {
      loading: true,
      error: false,

      algorithms: []
    };
  },
  computed: {
    
  },
  methods: {
  
    
  },
  async created() {
    try {
      let props = (await PropertyCollection.fetchAll({object: this.object})).array;
      let algoProperty = props.find(prop => prop.key == '@algo');

      if (!(algoProperty === undefined))
      {
        let algorithmsString = algoProperty.value.toString().replace('[','').replace(']','');
        algorithmsString = algorithmsString.split(' ').join('');
        let algorithmNames = algorithmsString.split(',');

        algorithmNames.forEach(algoName => {
          this.algorithms.push({'name': algoName, 'tag': ''})
        });
      }

      let index = 0;
      this.algorithms.forEach(algorithm => {
        let algoTag = props.find(prop => prop.key.replace('@', '') == algorithm.name);
        if (algoTag === undefined)
          algorithm.tag = 'no-tag';
        else if (algoTag.value == 0)
          algorithm.tag = 'no-tag';
        else if (algoTag.value == 1)
          algorithm.tag = 'red-tag';
        else if (algoTag.value == 2)
          algorithm.tag = 'orange-tag';
        else if (algoTag.value == 3)
          algorithm.tag = 'green-tag';
        else if (algoTag.value == -1)
          algorithm.tag = 'yellow-tag';

        index++;
      });
      
    }
    catch(error) {
      console.log(error);
      this.error = true;
    }
    this.loading = false;
  }
};
</script>

<style scoped>
em {
  margin-right: 0.5em;
}
</style>

<style>
.algorithms-wrapper .control, .algorithms-wrapper .tags {
  margin-bottom: 0 !important;
}

.algorithms-wrapper .tag {
  margin-right: 0.5em;
  margin-bottom: 0.3em !important;
}

.algorithms-wrapper .no-tag {
  background-color: rgba(0, 0, 0, 0.1);
}

.algorithms-wrapper .red-tag {
  background-color: rgba(170, 57, 57, 0.6);
}

.algorithms-wrapper .green-tag {
  background-color: rgba(45, 136, 45, 0.6);
}

.algorithms-wrapper .orange-tag {
  background-color: rgba(170, 108, 57, 0.6);
}

.algorithms-wrapper .yellow-tag {
  background-color: rgba(255, 200, 87, 0.6);
}

.algorithms-wrapper .field.is-grouped.is-grouped-multiline {
  margin-bottom: 0 !important;
  justify-content: center;
}
</style>