<template>
  <div class="annotations-list-wrapper">
    <div v-show="opened" class="annotations-list-opened">
      <button class="delete" @click="opened = false"></button>

      <div class="annotations-list-sidebar">
        <ontology-tree
          v-model="selectedTermsIds"
          :ontology="ontology"
          :multiple-selection="false"
          :hidden-nodes="hiddenTermsIds"
          :additional-nodes="[noTermOption]"
          :radio-selection="true"
        />

        <div class="column filter">
          <div class="filter-label">
            {{$t('preview-size')}}
          </div>
          <div class="filter-body">
            <cytomine-multiselect
              v-model="annotationsSize"
              :options="allowedSizes"
              label="label"
              track-by="size"
              :allow-empty="false"
              :searchable="false"
            />
          </div>
        </div>
      </div>

      <div class="annotations-list-container"> <!-- originalmente :usersIds="layersIds" -->
        <list-annotations-by-term
          :size="annotationsSize.size"
          color=""
          :nbPerPage="nbPerPage"

          :allTerms="terms"
          :allUsers="allUsers"
          :allImages="[image]"

          :term="findTerm(selectedTermId)"
          :multipleTerms="false"
          :noTerm="noTerm"
          :imagesIds="[image.id]"
          :usersIds="fixedLayersIds"
          :reviewed="false"

          :revision="revision"

          :showImageInfo=false

        
          @centerView="centerView($event)"
          @select="select($event)"
          @update="revision++"
        />
      </div>
      
    </div>

    <div v-show="!opened" class="opener" @click="opened = true">{{$t("annotations-list")}} <i class="fas fa-caret-up"></i></div>
  </div>
</template>

<script>

import {get, sync, syncMultiselectFilter} from '@/utils/store-helpers';

import {UserCollection, UserJobCollection, Annotation} from 'cytomine-client';

import CytomineTerm from '@/components/ontology/CytomineTerm';
import CytomineMultiselect from '@/components/form/CytomineMultiselect';
import CytomineSlider from '@/components/form/CytomineSlider';
import AnnotationPreview from '@/components/annotations/AnnotationPreview';
import OntologyTree from '@/components/ontology/OntologyTree';
import ListAnnotationsByTerm from '@/components/annotations/ListAnnotationsByTerm';

import {fullName} from '@/utils/user-utils.js';

import WKT from 'ol/format/WKT';

// store options to use with store helpers to target projects/currentProject/listImages module
const storeOptions = {rootModuleProp: 'storeModule'};
// redefine helpers to use storeOptions and correct module path
const localSyncMultiselectFilter = (filterName, options) => syncMultiselectFilter(null, filterName, options, storeOptions);

export default {
  name: 'annotations-list-custom',
  components: {
    ListAnnotationsByTerm,
    OntologyTree,
    CytomineTerm,
    CytomineMultiselect,
    CytomineSlider,
    AnnotationPreview
  },
  props: [
    'index',
    'view'
  ],
  data() {
    return {
      nbPerPage: 100,
      selectedTermsIds: [],
      noTermOption: {id: 0, name: this.$t('no-term')},

      users: [],
      userJobs: [],
      fixedLayers: [], 

      revision: 0,

      allowedSizes: [
        {label: this.$t('small'), size: 85},
        {label: this.$t('medium'), size: 125},
        {label: this.$t('large'), size: 200}
      ],

    };
  },
  computed: {
    ontology: get('currentProject/ontology'),
    project: get('currentProject/project'),

    storeModule() { // path to the vuex module in which state of this component is stored (projects/currentProject/listAnnotations)
      return this.$store.getters['currentProject/currentProjectModule'] + 'listAnnotations';
    },
    imageModule() {
      return this.$store.getters['currentProject/imageModule'](this.index);
    },
    imageWrapper() {
      return this.$store.getters['currentProject/currentViewer'].images[this.index];
    },
    viewerWrapper() {
      return this.$store.getters['currentProject/currentViewer'];
    },
    terms() {
      return this.$store.getters['currentProject/terms'] || [];
    },
    hiddenTermsIds() {
      return this.$store.getters[this.imageModule + 'hiddenTermsIds'];
    },
    additionalNodes() {
      return [this.noTermOption];
    },
    image() {
      return this.imageWrapper.imageInstance;
    },
    isActiveImage() {
      return this.viewerWrapper.activeImage === this.index;
    },
    // layers() {
    //   let layers = this.imageWrapper.layers.selectedLayers || [];
    //   layers = layers.filter(layer => layer.visible);
    //   layers.forEach(layer => layer.fullName = fullName(layer));
    //   return layers;
    // },
    // layersIds() {
    //   return this.layers.map(layer => layer.id);
    // },
    fixedLayersIds(){
      return this.fixedLayers.map(layer => layer.id);
    },
    allUsers() {
      let allUsers = this.users.concat(this.userJobs);
      allUsers.forEach(user => user.fullName = fullName(user));
      return allUsers;
    },
    opened: {
      get() {
        return this.imageWrapper.annotationsList.open;
      },
      set(value) {
        this.$store.commit(this.imageModule + 'setShowAnnotationsList', value);
      }
    },
    noTerm() {
      return this.selectedTermsIds.includes(this.noTermOption.id);
    },
    availableTerms() {
      return [...this.terms, this.noTermOption];
    },
    selectedTermId() {
      return (this.selectedTermsIds.length > 0) ? this.selectedTermsIds[0] : null;
    },
    annotationsSize: sync('previewSize', storeOptions)
  },
  methods: {
    findTerm(idTerm) {
      return this.availableTerms.find(term => term.id === idTerm);
    },
    async fetchUsers() { // TODO in vuex (project module)
      this.users = (await UserCollection.fetchAll()).array;
    },
    async fetchUserJobs() { // TODO in vuex (project module)
      this.userJobs = (await UserJobCollection.fetchAll({
        filterKey: 'project',
        filterValue: this.image.project
      })).array;
    },
    addAnnotationHandler(annotation) {
      if(annotation.image === this.image.id) {
        this.revision++;
      }
    },
    // reloadAnnotationsHandler(idImage) {
    //   if(idImage == null || idImage === this.image.id) {
    //     this.revision++;
    //   }
    // },
    editAnnotationHandler(updatedAnnot) {
      if(updatedAnnot.image === this.image.id) {
        this.revision++;
      }
    },
    deleteAnnotationHandler(deletedAnnot) {
      if(deletedAnnot.image === this.image.id) {
        this.revision++;
      }
    },
    async select(annot) {
      this.$eventBus.$emit('selectAnnotation', {index: this.index, annot});
      
      this.centerView(annot);
    },

    async centerView(annot)
    {
      let annotation = await Annotation.fetch(annot.id);

      let geometry = new WKT().readGeometry(annotation.location);
      this.view.fit(geometry, {padding: [10, 10, 10, 10], maxZoom: this.image.depth});

      // HACK: center set by view.fit() is incorrect => reset it manually
      this.center = (geometry.getType() === 'Point') ? geometry.getFirstCoordinate()
        : [annotation.centroid.x, annotation.centroid.y];

    },

    shortkeyHandler(key) {
      if (!this.isActiveImage) {
        return;
      }

      if (key === 'toggle-annotations') {
        this.opened = !this.opened;
      }
    },
    async getFixedLayers () {
      let userLayers = (await this.project.fetchUserLayers(this.image.id)).array;
      userLayers.forEach(layer => layer.fullName = fullName(layer));
      // userLayers = userLayers.filter(layer => layer.visible);
      this.fixedLayers = userLayers;
    },
    reloadDetections(){
      this.getFixedLayers();
    }
  },
  async created() {
    //this.availableTerms = [...this.terms, this.noTermOption];
    this.selectedTermsIds = [this.availableTerms[0].id];

    this.getFixedLayers();

    // if store was not yet initialized, set default values
    if(!this.annotationsSize) 
    {
      this.annotationsSize = this.allowedSizes[1]; // Mediana
    }

    this.fetchUsers();
    this.fetchUserJobs();
  },
  mounted() {
    this.$eventBus.$on('reloadDetections', this.reloadDetections);
    this.$eventBus.$on('addAnnotation', this.addAnnotationHandler);
    // this.$eventBus.$on('reloadAnnotations', this.reloadAnnotationsHandler);
    this.$eventBus.$on('editAnnotation', this.editAnnotationHandler);
    this.$eventBus.$on('deleteAnnotation', this.deleteAnnotationHandler);
    this.$eventBus.$on('shortkeyEvent', this.shortkeyHandler);
  },
  beforeDestroy() {
    // unsubscribe from all events
    this.$eventBus.$on('reloadDetections', this.reloadDetections);
    this.$eventBus.$off('addAnnotation', this.addAnnotationHandler);
    // this.$eventBus.$off('reloadAnnotations', this.reloadAnnotationsHandler);
    this.$eventBus.$off('editAnnotation', this.editAnnotationHandler);
    this.$eventBus.$off('deleteAnnotation', this.deleteAnnotationHandler);
    this.$eventBus.$off('shortkeyEvent', this.shortkeyHandler);
  }
};
</script>

<style scoped>
.annotations-list-opened {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  background: #f5f5f5;
  height: 25vh;
  pointer-events: auto;
  display: flex;
}

.delete {
  position: absolute;
  right: 25px;
  top: 7px;
  z-index: 10;
}

.annotations-list-container {
  overflow: auto;
  position: relative;
  border-bottom: 1px solid #ccc;
  height: 100%;
  width: 100%;
  padding-right: 25px;
}

.opener {
  background: #f5f5f5;
  width: 150px;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  margin: auto;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.3px;
  cursor: pointer;
  pointer-events: auto;
}

.opener .fas {
  margin-left: 5px;
  font-size: 12px;
  line-height: 10px;
}

.box {
  background: unset;
  border-radius: unset;
  box-shadow: unset;
  padding: 0.75rem;
  height: 100%;
}

>>> h2 {
  margin-bottom: 0;
}

.annotations-list-sidebar {
  padding: 10px;
  overflow-y: auto;
  min-width: 18em;
}

>>> ul.pagination-list {
  justify-content: flex-end;
}
</style>
