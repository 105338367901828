<template>
<form @submit.prevent="setBoxSizes()">
  <cytomine-modal :active="active" :title="$t('box-size-modal-label')" @close="$emit('update:active', false)">
    
    <!-- Ontology Tree with size selector -->
    <ontology-tree-box-size :ontology="ontology" :allowSelection="false" :allowDrag="false" :allowEdition="false" @updateBoxSizes="updateBoxSizes">
      <template #no-result>
        <em class="has-text-grey">{{$t('no-term')}}</em>
      </template>
    </ontology-tree-box-size>

    <template #footer>
      <button class="button" type="button" @click="$emit('update:active', false)">
        {{$t('button-cancel')}}
      </button>
      <button class="button is-link" :disabled="errors.any()">
        {{$t('button-save')}}
      </button>
    </template>
  </cytomine-modal>
</form>
</template>

<script>
import {Ontology} from 'cytomine-client';
import CytomineModal from '@/components/utils/CytomineModal';
import OntologyTreeBoxSize from '@/components/viewer/panels/exportation-panel-templates/OntologyTreeBoxSize.vue';
import {get} from '@/utils/store-helpers';

export default {
  name: 'box-size-modal',
  props: {
    active: Boolean
  },
  components: {CytomineModal, OntologyTreeBoxSize},
  $_veeValidate: {validator: 'new'},
  data() {
    return {
      boxSizes: null,
    };
  },
  computed: {
    ontology: get('currentProject/ontology'),
  },
  watch: {
    active(val) {
      if(val) {
        this.boxSizes = null;
        this.boxOffsets = null;
      }
    }
  },
  methods: {
    async updateBoxSizes({boxSizes}){
      this.boxSizes = boxSizes
    },
    async setBoxSizes() {      
      try {
        this.$notify({type: 'success', text: this.$t('notif-success-set-box-sizes')});
        this.$emit('finalBoxSizes', {finalBoxSizes: this.boxSizes});
        this.$emit('update:active', false);
      }
      catch(error) {
        console.log(error);
        this.$notify({type: 'error', text: this.$t('notif-error-set-box-sizes')});
      }
    }
  }
};
</script>