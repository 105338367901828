

/**
 * Remove timestamo and unique identifier from project name
 *
 * @param {String} name The name of the project
 * 
 * @returns {String} The name of the project without identifiers
 */
export function removeUniqueIdentifier(name)
{
    let substrings = name.split('@')
    
    if (substrings.length > 1)
        return substrings.slice(0, -1).join('@');
    else
        return name;
}